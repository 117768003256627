import Titlebar from "../../components/Titlebar";
import {AlarmWithInfo, TempState} from "../../types/componentTypes";
import {useEffect, useState} from "react";
import {
  Alarm, Annotation,
  Asset,
  Building,
  Device,
  DeviceDeployment,
  DeviceStatus,
  ModelTemplate,
  Organization,
  Room,
} from "../../types/dataTypes"
import {DeviceStatusWithInfo} from "../../types/componentTypes";
import {getActiveModelTemplates, getAlarms, getDeviceConditionCached} from "../../api/api";
import PaginatedAlarmSuggestions from "./components/PaginatedAlarmSuggestions";
import GenericFilter from "../../components/GenericFilter";
import {Selectable} from "../../components/GenericFilter/GenericFilter";
import { getAnnotations } from "../../api/api-ts"


type MainState =
  {
    state: TempState
  }


export default function AlarmSuggestionDashboard({state}: MainState) {

  const [alarmSuggestions, setAlarmSuggestions] = useState<Alarm[]>([])
  const [search, setSearch] = useState<string>("");
  const [anomalyProbabillityVisible, setAnomalyProbabillityVisible] = useState<boolean>(false)
  const [alarmWithInfo, setAlarmWithInfo] = useState<AlarmWithInfo[]>([])
  const [annotations, setAnnotations] = useState<Annotation[]>([])
  const [filteredAlarmWithInfo, setFilteredDeviceStatusWithInfo] = useState<AlarmWithInfo[]>([])

  //Filter states
  const [filteredOrgs, setFilteredOrgs] = useState<Organization[]>()
  const [filteredBuildings, setFilteredBuildings] = useState<Building[]>()
  const [filteredRooms, setFilteredRooms] = useState<Room[]>()

  useEffect(() => {
    let now = new Date()
    let KEEP_SUGGESTION_DAYS: number = 30
    const fetchAlarmSuggestions = async () => {
      let alarmSuggestions: Alarm[] = await getAlarms([], [], new Date(now.getTime() - KEEP_SUGGESTION_DAYS * 24 * 60 * 60 * 1000), now, 0)
      let annotations: Annotation[] = []
      for (let i = 0; i < alarmSuggestions.length; i += 10) {
        let subArray = alarmSuggestions.slice(i, i + 10)
        annotations.push(...(await getAnnotations(subArray.map(a => a.device_id), [], undefined, subArray.map(a => a.id))))
      }
      setAnnotations(annotations)
      setAlarmSuggestions(alarmSuggestions)
    }

    fetchAlarmSuggestions()

    setFilteredOrgs(state.organizations.sort((a, b) => a.name.localeCompare(b.name)))
    setFilteredRooms(state.rooms?.sort((a, b) => a.name && b.name ? a.name.localeCompare(b.name) : -1))
    setFilteredBuildings(state.buildings?.sort((a, b) => {
        let aval = a.name ?? a.address
        let bval = b.name ?? b.address
        return aval.localeCompare(bval)
      })
    )
  }, [state])

  useEffect(() => {

    const updateAlarms = async () => {
      let gatheredDeviceInfo: AlarmWithInfo[] = []
      for (const as of alarmSuggestions) {
        let device = state.devices.find(d => d.id === as.device_id)
        let device_deployment = state.deviceDeployments?.filter(dd => dd.device_id === as.device_id).sort((a, b) => a.start_time - b.start_time)[0]
        let room: Room | undefined = state.rooms?.find(r => r.id === device_deployment?.room_id)
        let building: Building | undefined = room !== undefined ? state.buildings?.find(b => b.id === room?.building_id) : undefined
        let deviceDeployment = state.deviceDeployments?.find(dd => dd.id === device_deployment?.id)
        let temporg = state.organizations.find(o => o.id === device?.organization_id)
        let alarmWithInfo: AlarmWithInfo = {
          "alarm": as,
          "device": device,
          "deviceDeployment": deviceDeployment,
          "room": room,
          "building": building,
          "organization": temporg,
          "annotations": annotations.filter(an => an.alarm_id === as.id),
        }
        gatheredDeviceInfo.push(alarmWithInfo)
      }
      // Remove alarms that has an annotation
      gatheredDeviceInfo = gatheredDeviceInfo.filter( awi => {
        return awi.annotations == undefined || awi.annotations.length < 1
      } )
      setAlarmWithInfo(gatheredDeviceInfo)
    }

    updateAlarms()

  }, [alarmSuggestions, annotations])


  useEffect(() => {
    if (search !== undefined) {
      const timeOutId = setTimeout(() => setFilteredDeviceStatusWithInfo(
        filterDataBySearch()
      ), 1000)
      return () => clearTimeout(timeOutId)
    } else {
      setFilteredDeviceStatusWithInfo(alarmWithInfo.sort((a, b) => {
        return a.alarm.timestamp - b.alarm.timestamp
      }).reverse())
    }
  }, [search, alarmWithInfo, filteredOrgs, filteredBuildings, filteredRooms])

  const filterDataBySearch = () => {
    const searchRegex = new RegExp(search, "i") // 'i' means ignore case
    let filteredRows = alarmWithInfo.filter(row => {
      let unlikelySearchString = "THIS SHOULD NEVER BE CONTAINED IN THIS SEARCH"
      return (
        searchRegex.test(row.room?.name ?? unlikelySearchString) ||
        searchRegex.test(row.room?.nice_name ?? unlikelySearchString) ||
        searchRegex.test(row.building?.address ?? unlikelySearchString) ||
        searchRegex.test(row.building?.name ?? unlikelySearchString) ||
        searchRegex.test(row.deviceDeployment?.assets[0].name ?? unlikelySearchString) ||
        searchRegex.test(row.deviceDeployment?.assets[0].id ?? unlikelySearchString) ||
        searchRegex.test(row.device?.id ?? unlikelySearchString) ||
        searchRegex.test(row.device?.serial ?? unlikelySearchString) ||
        searchRegex.test(row.organization?.name ?? unlikelySearchString)
      )
    })

    filteredRows = filteredRows.filter(r => filteredOrgs?.map(o => o.id).includes(r.organization?.id ?? "YOLO"))
    filteredRows = filteredRows.filter(r => filteredBuildings?.map(o => o.id).includes(r.building?.id ?? "YOLO"))
    filteredRows = filteredRows.filter(r => filteredRooms?.map(o => o.id).includes(r.room?.id ?? "YOLO"))

    filteredRows = filteredRows.sort((a, b) => {
      return a.alarm.timestamp - b.alarm.timestamp
    })
    return filteredRows.reverse()
  }

  return (
    <>
      <Titlebar headline="Confirm Alarm Suggestions"/>
      <div className="tw-flex tw-flex-col tw-items-center tw-mt-2">
        <div className="tw-flex tw-flex-row tw-space-x-5">
          <GenericFilter title={"Organizations"} setSelected={(orgs: Organization[]) => {
            setFilteredOrgs(orgs);
          }} mapAvailableToSelectable={function (o: Organization) {
            return {id: o.id, name: o.name}
          }} selected={filteredOrgs} available={state.organizations}/>

          <GenericFilter title={"Buildings"} setSelected={(buildings: Building[]) => {
            setFilteredBuildings(buildings);
          }} mapAvailableToSelectable={function (b: Building) {
            return {id: b.id, name: b.name ?? b.address}
          }} selected={filteredBuildings} available={state.buildings}/>

          <GenericFilter title={"Rooms"} setSelected={(rooms: Room[]) => {
            setFilteredOrgs(rooms);
          }} mapAvailableToSelectable={function (r: Room) {
            return {id: r.id, name: r.name}
          }} selected={filteredRooms} available={state.rooms}/>
        </div>
      </div>
      <div className={"tw-flex tw-flex-col tw-items-center"}>
        <input type='text' value={search} placeholder={"Search for anything.."}
               onChange={e => setSearch(e.target.value)}
               className={"tw-mx-10 tw-mt-10 tw-text-center tw-h-[60px] tw-rounded-full tw-w-1/2"}/>
        <div className={"tw-flex-row"}>
          <p className={"tw-inline-block tw-mr-2"}>Show Probabillity: </p>
          <input type={"checkbox"} onChange={() => setAnomalyProbabillityVisible(!anomalyProbabillityVisible)}
                 checked={anomalyProbabillityVisible}/>
        </div>
        <PaginatedAlarmSuggestions devicesPerPage={5} anomalyProbabillityVisible={anomalyProbabillityVisible}
                                   alarmSuggestions={filteredAlarmWithInfo}/>
      </div>
    </>
  )
}