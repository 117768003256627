import Datetime from "react-datetime"
import {
  UserPreferencesForCategory,
  Annotation,
  Organization,
  Asset,
  AssetCategory,
  Building,
  Room,
  UserAccount, User,
} from "../types/dataTypes"

let credentials: RequestCredentials = "include"

export var options: RequestInit = {
  credentials: credentials, // pass cookies
}

function setBase() {
  if (process.env.REACT_APP_API_KEY === undefined || process.env.REACT_APP_API_URL === undefined) {
    return "http://localhost:5000"
  } else {
    options["headers"] = { "Authorization": "Bearer " + process.env.REACT_APP_API_KEY }
    options["credentials"] = "omit"
    return process.env.REACT_APP_API_URL
  }
}

export const base =
  process.env.NODE_ENV !== "production"
    ? setBase()
    : window.location.origin


export const getModelTemplates = async (device_ids: string[], model_ids: string[], model_names: string[],
                                        start_time?: Date, end_time?: Date, active_at?: Date, active_after?: Date, include_devices?: Boolean) => {
  let url = `${base}/v1/ml/templates`
  let params = new URLSearchParams(
    {},
  )
  if (start_time) {
    params.append("start_time", (Math.floor(start_time.getTime() / 1000)).toString())
  }
  if (end_time) {
    params.append("end_time", (Math.floor(end_time.getTime() / 1000)).toString())
  }
  if (active_at) {
    params.append("active_at", (Math.floor(active_at.getTime() / 1000).toString()))
  }
  if (active_after) {
    params.append("active_after", Math.floor(active_after.getTime() / 1000).toString())
  }
  if (include_devices !== undefined) {
    let incl = include_devices ? 1 : 0
    params.append("include_devices", incl.toString())
  }
  device_ids.forEach(id => {
    params.append("device", id)
  })
  model_ids.forEach(id => {
    params.append("id", id)
  })
  model_names.forEach(name => {
    params.append("name", name)
  })
  url = url + "?" + params
  let requestInit: RequestInit = { ...options, method: "GET" }
  let response = await fetch(url, requestInit)
  let data = await response.json()
  return data.data

}

export const getAnnotations = async (device_id?: string[],
                                     organization_id?: string[],
                                     model_template_id?: string,
                                     alarm_id?: string[]): Promise<Annotation[]> => {
  let url = `${base}/v1/annotations`
  let params = new URLSearchParams(
    {},
  )

  if (device_id !== undefined) {
    device_id.forEach(element => {
      params.append("device_id", element)
    })
  }
  if (organization_id !== undefined) {
    organization_id.forEach(element => {
      params.append("organization_id", element)
    })
  }
  if (model_template_id !== undefined) {
    params.append("model_template_id", model_template_id)
  }
  if (alarm_id !== undefined) {
    alarm_id.forEach(element => {
      params.append("alarm_id", element)
    })
  }

  url = url + "?" + params
  let requestInit: RequestInit = { ...options, method: "GET" }
  let response = await fetch(url, requestInit)
  let data = await response.json()
  return data.data

}

export const postAnnotation = async (annotation: Annotation) => {
  let url = `${base}/v1/annotations`
  options.headers = new Headers({ "content-type": "application/json" })
  let body = JSON.stringify(annotation)
  let response = await fetch(url, { ...options, method: "POST", body: body })
  let data = await response.json()
  return data["data"]
}

export const getUserPreferences = async (): Promise<UserPreferencesForCategory[]> => {
  let url = `${base}/v1/userpreferences`
  let response = await fetch(url, { ...options, method: "GET" })
  let data = await response.json()
  return data["data"]
}

export const updateUserPreferences = async (preference: UserPreferencesForCategory): Promise<UserPreferencesForCategory> => {
  let url = `${base}/v1/preferences/${preference.id}`
  options.headers = new Headers({ "content-type": "application/json" })
  let body = JSON.stringify(preference)
  let response = await fetch(url, { ...options, method: "PUT", body: body })
  let data = await response.json()
  return data["data"]
}

export const getOrganizations = async (): Promise<Organization[]> => {
    let url = `${base}/v1/organizations`
    let response = await fetch(url, { ...options, method: "GET" })
    let data = await response.json()
    return data["data"]
}

export const getAssets = async (): Promise<Asset[]> => {
  let url = `${base}/v1/assets`
  let response = await fetch(url, { ...options, method: "GET" })
  let data = await response.json()
  return data["data"]
}

export const getAssetCategories = async (): Promise<AssetCategory[]> => {
  let url = `${base}/v1/asset_categories`
  let response = await fetch(url, { ...options, method: "GET" })
  let data = await response.json()
  return data["data"]
}

export const postAsset = async (asset: Asset) => {
  let url = `${base}/v1/assets`
  options.headers = new Headers({ "content-type": "application/json" })

  if (asset.parent_id == "") {
    delete asset.parent_id;
  }

  if (asset.room_position_id == "") {
    delete asset.room_position_id;
  }

  let body = JSON.stringify(asset)
  console.log(body)
  let response = await fetch(url, { ...options, method: "POST", body: body })
  let data = await response.json()
  return data["data"]
}

export const postBuilding = async (building: Building) => {
  let url = `${base}/v1/buildings`;
  options.headers = new Headers({ "content-type": "application/json" })
  let body = JSON.stringify(building)
  let response = await fetch(url, { ...options, method: "POST", body: body })
  let data = await response.json();
  return data["data"];
};

export const putBuilding = async (building_id: string, newName?: string, metaChanges?: { [key: string]: any }): Promise<Building> => {
  let url = `${base}/v1/buildings/${building_id}`
  options.headers = new Headers({ "content-type": "application/json" })
  let content: {[key: string]: any} = {}
  if(newName){
    content["name"] = newName
  }
  if(metaChanges){
    if(Object.keys(metaChanges).includes("muted_to") && typeof metaChanges["muted_to"] == "number"){
      metaChanges["muted_to"] = metaChanges["muted_to"] / 1000
    }
    content["meta"] = metaChanges
  }
  let body = JSON.stringify(content)
  let response = await fetch(url, { ...options, method: "PUT", body: body })
  let data = await response.json()
  return data["data"]
}

//TODO: Maybe delete this and use the one above.
export const updateBuilding = async (building: Building) => {
  let url = `${base}/v1/buildings/${building.id}`;
  options.headers = new Headers({ "content-type": "application/json" })
  let body = JSON.stringify(building)
  let response = await fetch(url, { ...options, method: "PUT", body: body })
  let data = await response.json();
  return data["data"];
};

export const postRoom = async (room: Room) => {
  let url = `${base}/v1/rooms`
  options.headers = new Headers({ "content-type": "application/json" })
  // Generate room id if not supplied
  let body = room.id === "" ? JSON.stringify({ ...room, id: undefined }) : JSON.stringify(room)
  let response = await fetch(url, { ...options, method: "POST", body: body })
  let data = await response.json()
  return data["data"]
}

//TODO: Maybe delete this and use the one above.
export const updateRoom = async (room: Room) => {
  let url = `${base}/v1/rooms/${room.id}`
  options.headers = new Headers({ "content-type": "application/json" })
  let body = JSON.stringify(room)
  let response = await fetch(url, { ...options, method: "PUT", body: body })
  let data = await response.json();
  return data["data"];
};

export const createDeviceDeployment = async (deviceToDeploy :  {
  device_id: string,
  room_id: string,
  description: string,
  start_time: number,
  assets: string[],
}) => {

  let url = `${base}/v1/devicedeployments`;
 
  options.headers = new Headers({
      'content-Type': 'application/json', 
  });

  let body = JSON.stringify(deviceToDeploy)

  let response = await fetch(url, {...options, method: "POST", body: body});
  
  let data = await response.json();
  console.log(data);
  return data["data"];
};

export const updateOrganizationDevices = async (orgId: string, deviceIds: string[]) => {
  let url = `${base}/v1/organizations/${orgId}`;
  const devices = deviceIds.map(id => ({ id }));
  const options = { 
      headers: new Headers({
          'Content-Type': 'application/json'
      }),
      method: "PUT", 
      body: JSON.stringify({ devices })
  };
  let response = await fetch(url, options);
  let data = await response.json();
  return data["data"];
};

export const uploadImage = async (file: File, onUploadProgress: any): Promise<any> => {
  let formData = new FormData()
  formData.append("file", file)

  let url = `${base}/path/to/imageupload`
  options.headers = new Headers({
    'content-Type': 'multipart/form-data',
  })
  let body = formData

  let response = await fetch(url, {...options, method: "POST", body: body})

  let data = await response.json()
  return data["data"]
}

export const getImage = async (): Promise<any> => {
  let url = `${base}/path/to/getimage`
  let response = await fetch(url, { ...options, method: "GET" })
  let data = await response.json()
  return data["data"]
}

export const getUser = async (user_id: string): Promise<User> => {
  let url = `${base}/v1/users/${user_id}`
  let response = await fetch(url, { ...options, method: "GET" })
  let data = await response.json()
  return data["data"]
}