import {
  getAlarmsByDeviceId, getAnomalyDetectionResults,
  postAlarm,
} from "../../../api/api"
import {
  Alarm as AlarmModel, Annotation, AnnotationFormData,
  AnomalyDetectionResult, AnomalyGraphDataMap,
  Device, ModelTemplate,
} from "../../../types/dataTypes"
import React, { ChangeEvent, useEffect, useState } from "react"
import Button from "../../../components/Button"
import { Data, Layout, PlotMouseEvent } from "plotly.js"
import TrafficLight from "../../../components/TrafficLight"
import SectionWrapper from "../../../components/SectionWrapper"
import {
  SimpleGraphDates,
  simpleGraphTimeConverter,
} from "../../../components/AnomalyGraph/Components/SimpleDateSelect"
import { DeviceStatusWithInfo } from "../../../types/componentTypes"
import { getAnnotations, getModelTemplates, postAnnotation } from "../../../api/api-ts"
import AnomalyGraph, { createPreviousAlarmXYAndText } from "../../../components/AnomalyGraph"
import AnnotationForm from "../../../components/AnnotationForm"

let ANOMALY_REVIEW_TAG = "anomaly_review"

export default function Alarm({
                                device, status, anomalyProbabillityVisible,
                              }: {
  device: Device,
  status: DeviceStatusWithInfo,
  anomalyProbabillityVisible: boolean
}) {

  type AlarmForm = {
    timestamp?: number,
    description?: string,
    alarm_type: "A" | "B",
    device_id: string
  }

  enum ActionState {
    default,
    alarm,
    alarmConfirm,
    annotation,
    markingDone,
    done
  }

  const [anomalyGraphDataMap, setAnomalyGraphDataMap] = useState<AnomalyGraphDataMap>({})
  const [modelTemplates, setModelTemplates] = useState<ModelTemplate[]>([])
  const [existingAlarms, setExistingAlarms] = useState<AlarmModel[]>([])
  const [annotations, setAnnotations] = useState<Annotation[]>([])
  const [alarmFormData, setAlarmFormData] = useState<AlarmForm>({ device_id: device.id, alarm_type: "A" })
  const [selectedTime, setSelectedTime] = useState<SimpleGraphDates>("Last 30 days")
  const [actionState, setActionState] = useState<ActionState>(ActionState.default)
  const [annotationFormData, setAnnotationFormData] = useState<AnnotationFormData>({
    description: "",
    tags: [],
  })
  const [loading, setLoading] = useState<boolean>(false)


  useEffect(() => {
    const fetchAndSetExistingAlarmsAndAnnotations = async () => {
      //TODO: Get some query level date filtering in here.
      const startTime = Math.floor((new Date().getTime() - 90 * 24 * 60 * 60 * 1000) / 1000)
      let alarms: AlarmModel[] = await getAlarmsByDeviceId(device.id, undefined)
      alarms = alarms.filter(a => a.timestamp >= startTime)
      let annotations = await getAnnotations([device.id],
        undefined, undefined)
      annotations = annotations.filter(an => an.end_time >= startTime)
      if (alarms.length > 0) {
        let alarmsSentOrUnhandled: AlarmModel[] = alarms.filter(a =>
          a.customer_visible || !annotations.map(an => an.alarm_id).includes(a.id),
        )
        setExistingAlarms(alarmsSentOrUnhandled)
      }
      setAnnotations(annotations)
    }
    fetchAndSetExistingAlarmsAndAnnotations()
  }, [])

  useEffect(() => {
    setLoading(true)
    const fetchAndSetAnomalyDetectionResults = async () => {
      const endTime = new Date()
      const startTime = new Date(endTime.getTime() - simpleGraphTimeConverter(selectedTime) * 24 * 60 * 60 * 1000)
      let modelTemplates: ModelTemplate[] = await getModelTemplates([device.id], [], [], undefined, undefined, undefined, startTime, false)
      modelTemplates = modelTemplates.filter(mt => (mt.end_time === undefined || mt.end_time === null) || mt.end_time > startTime.getTime() / 1000)
      let anomalyGraphDataResult: AnomalyGraphDataMap = {}
      for (const mlt of modelTemplates) {
        anomalyGraphDataResult[mlt.id] = await getAnomalyDetectionResults(device.id, startTime, endTime, [mlt.id], "end_time")
      }
      setModelTemplates(modelTemplates)
      setAnomalyGraphDataMap(anomalyGraphDataResult)
      setLoading(false)
    }
    fetchAndSetAnomalyDetectionResults()
  }, [selectedTime])

  const handleGraphClick = (data: Readonly<PlotMouseEvent>) => {
    let time: Date
    if (typeof data.points[0].x === "string") {
      time = new Date(data.points[0].x)
    } else {
      console.log("Error: Graph click did not return expected values")
      return
    }

    if (actionState == ActionState.alarm) {
      setAlarmFormData({ ...alarmFormData, timestamp: new Date(data.points[0].x).getTime() / 1000 })
    }
    if (actionState == ActionState.annotation) {
      if (annotationFormData.start_time === undefined){
        setAnnotationFormData({ ...annotationFormData, start_time: time })}
      else if( time <= annotationFormData.start_time && annotationFormData.end_time === undefined){
        setAnnotationFormData({ ...annotationFormData, start_time: time, end_time: undefined })
      }
      else if (annotationFormData.start_time !== undefined && annotationFormData.end_time !== undefined) {
        setAnnotationFormData({ ...annotationFormData, start_time: time, end_time: undefined })
      } else {
        setAnnotationFormData({ ...annotationFormData, end_time: time })
      }
    }
  }


  const onAlarmFormChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAlarmFormData({ ...alarmFormData, [event.target.name]: event.target.value })
  }
  const formatDate = (date: Date) => {
    const pad = (a: number) => {
      if (a >= 10) {
        return `${a}`
      } else {
        return `0${a}`
      }
    }
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`
  }

  function addCustomDataFromForm(plotData: Data[], yLowerRange: number, yUpperRange: number) {
    const yLowerRangeWithMargins = yLowerRange - (yUpperRange - yLowerRange) * 0.25
    const yUpperRangeWithMargins = yUpperRange + (yUpperRange - yLowerRange) * 0.25

    let alarmUnderCreation: AlarmModel | undefined

    if (actionState == ActionState.alarm && alarmFormData.timestamp) {
      const alarmUnderCreation = {
        id: "not-important",
        creator_id: "not-important",
        created_at: -1,
        alarm_type: alarmFormData.alarm_type,
        device_id: device.id,
        timestamp: alarmFormData.timestamp,
        resolved: false,
      }
      plotData.push({
        ...createPreviousAlarmXYAndText(alarmUnderCreation, 100, yLowerRangeWithMargins, yUpperRangeWithMargins),
        legendgroup: "alarms",
        mode: "lines+markers",
        marker: { color: "rgba(255, 255, 255, 0)", size: 16 },
        showlegend: false,
        name: "Draft alarm",
        line: {
          dash: "dash",
          width: 4,
          color: "black",
        },
      })
    }

    if (actionState == ActionState.annotation && annotationFormData.start_time) {
      let fake_alarm_type: "A" | "B" = "B"
      //Fake an alarm to reuse method for alarm
      const annotationUnderCreation = {
        id: "not-important",
        creator_id: "not-important",
        created_at: -1,
        alarm_type: fake_alarm_type,
        device_id: device.id,
        timestamp: annotationFormData.start_time.getTime() / 1000,
        resolved: false,
        text: "Start Time",
      }
      plotData.push({
        ...createPreviousAlarmXYAndText(annotationUnderCreation, 100, yLowerRangeWithMargins, yUpperRangeWithMargins),
        legendgroup: "createAnnotations",
        mode: "lines+markers",

        marker: { color: "rgba(255, 255, 255, 0)", size: 16 },
        showlegend: false,
        name: "Start",
        line: {
          width: 2,
          color: "cyan",
        },
      })
    }

    if (actionState == ActionState.annotation && annotationFormData.end_time) {
      let fake_alarm_type: "A" | "B" = "B"
      //Fake an alarm to reuse method for alarm
      const annotationUnderCreation = {
        id: "not-important-end-time",
        creator_id: "not-important",
        created_at: -1,
        alarm_type: fake_alarm_type,
        device_id: device.id,
        timestamp: annotationFormData.end_time.getTime() / 1000,
        resolved: false,
        text: "End Time",
      }
      plotData.push({
        ...createPreviousAlarmXYAndText(annotationUnderCreation, 100, yLowerRangeWithMargins, yUpperRangeWithMargins),
        legendgroup: "createAnnotations",
        mode: "lines+markers",
        fill: "tonextx",
        marker: { color: "rgba(255, 255, 255, 0)", size: 16 },
        showlegend: false,
        name: "End time",
        line: {
          width: 2,
          color: "cyan",
        },
      })
    }
  }

  function addCustomLayout(layout: Partial<Layout>, yLowerRange: number, yUpperRange: number) {
    if (!layout.shapes) {
      layout.shapes = []
    }
    let annotationsToDraw = annotations.filter(a => a.tags?.includes(ANOMALY_REVIEW_TAG + "_anomaly"))
    let counter = 0;
    for (let annotation of annotationsToDraw) {
      layout.shapes.push(
        {
          type: "rect",
          xref: "x",
          yref: "paper",
          x0: new Date(annotation.start_time * 1000),
          y0: 0,
          x1: new Date(annotation.end_time * 1000),
          y1: 1,
          fillcolor: "#0000ff",
          opacity: 0.2,
          line: {
            width: 1,
          },
          label: {

            text: annotation.description,
            font: { size: 8, color: "green" },
            textposition: counter%2==0 ? "top center" : "bottom center",
          },
        },
      )
      counter++
    }
    let lastCheckedAnnotations = filter_and_sort_period_checked_annotations(annotations)
    let now = new Date()
    let periodToCheckStart = new Date(now.getTime() - 7*24*60*60*1000)
    if(lastCheckedAnnotations.length > 0 && lastCheckedAnnotations[0].end_time > Math.floor(periodToCheckStart.getTime()/1000)){
      periodToCheckStart = new Date(lastCheckedAnnotations[0].end_time * 1000)
    }
    layout.shapes.push(
        {
          type: "rect",
          xref: "x",
          yref: "paper",
          x0: periodToCheckStart,
          y0: 0,
          x1: now,
          y1: 1,
          fillcolor: "#ffff00",
          opacity: 0.1,
          line: {
            width: 1,
          },
          label: {
            text: "Period To Check",
            font: { size: 10, color: "red" },
            textposition: "top center",
          },
        },
      )
  }

  const createAndPostAnnotation = async (start_time: Date, end_time: Date, tags: string[], description: string, anomaly: "YES" | "NO" | "MAYBE", successState: ActionState) => {

    let annotation: Annotation = {
      device_id: device.id,
      end_time: Math.floor(end_time.getTime() / 1000),
      start_time: Math.floor(start_time.getTime() / 1000),
      anomaly: anomaly,
      tags: tags.concat(["task_" + ANOMALY_REVIEW_TAG]).map(t => t.toLowerCase()),
      description: description,
    }
    try {
      if(end_time.getTime() < start_time.getTime()){
        throw Error("End Time is less than start time!!")
      }
      let result: Annotation = await postAnnotation(annotation)
      setAnnotations(annotations.concat([result]))
      setActionState(successState)
    } catch (e) {
      console.log(e)
    }
  }


  function renderActionState(state: ActionState) {
    switch (state) {
      case ActionState.default:
        return (
          <div className={"tw-space-x-2"}>
            <Button type="button" size="large" variant="secondary"
                    styles={"tw-max-w-xs"} onClick={() => {
              setActionState(ActionState.annotation)
            }}>
              <span>Mark Anomaly</span>
            </Button>
            <Button type="button" size="large" variant="secondary"
                    styles={"tw-max-w-xs"} onClick={() => {
              setActionState(ActionState.alarm)
            }}>
              <span>Create Alarm</span>
            </Button>
            <Button type="button" size="large" variant="secondary"
                    styles={"tw-max-w-xs"} onClick={() => {
              setActionState(ActionState.markingDone)
            }}>
              <span>Mark as Done</span>
            </Button>
          </div>
        )

      case ActionState.alarm:
      case ActionState.alarmConfirm:
        return (
          <form
            onSubmit={async (e) => {
              e.preventDefault()
              if (alarmFormData.description && alarmFormData.timestamp) {
                setActionState(ActionState.default)
                const result: AlarmModel = await postAlarm(alarmFormData)
                setExistingAlarms([...existingAlarms, result])
                setAlarmFormData({ ...alarmFormData, timestamp: undefined, description: undefined })
              }
            }}
            className={"xl:tw-w-96 tw-justify-items-center tw-text-center tw-content-center tw-grid tw-grid-cols-1 tw-content-center tw-bg-primary-off-white tw-text-secondary-darker-off-white tw-border-secondary-darker-off-white tw-p-2 tw-space-y-2 tw-border-2 tw-rounded-xl"}>
            <label className={"tw-flex tw-flex-col"}>
              <span>Alarm type</span>
              <fieldset className="tw-flex tw-space-x-1">
                <span>A</span>
                <input type={"radio"} defaultChecked={true} name={"alarm_type"} value={"A"}
                       onChange={onAlarmFormChange} />
                <span>B</span>
                <input type={"radio"} name={"alarm_type"} value={"B"} onChange={onAlarmFormChange} />
              </fieldset>
            </label>
            <label className={"tw-flex tw-flex-col tw-w-48"}>
              <span>Alarm timestamp:</span>
              <input className={"tw-rounded-md"} type="string" name="timestamp"
                     value={alarmFormData.timestamp ? formatDate(new Date(alarmFormData.timestamp * 1000)) : ""}
                     onChange={onAlarmFormChange} />
            </label>
            <label className={"tw-flex tw-flex-col"}>
              Alarm description
              <textarea className={"tw-rounded-md tw-w-72 tw-h-36"} name={"description"}
                        onChange={onAlarmFormChange} />
            </label>
            {actionState == ActionState.alarmConfirm &&
              <p className={"tw-text-red-500 tw-border tw-border-gray-300 tw-rounded"}> This will trigger an alarm for
                the
                customer, are you sure? </p>}
            <input className="hover:tw-text-gray-500" type={"button"} value={"Cancel"} onClick={() => {
              setActionState(ActionState.default)
              setAlarmFormData({ ...alarmFormData, timestamp: undefined, description: undefined })
            }
            } />
            {actionState == ActionState.alarm && <input type={"button"} value={"Submit"} onClick={() => {
              setActionState(ActionState.alarmConfirm)
            }
            } />}
            {actionState == ActionState.alarmConfirm &&
              <input className="tw-text-green-400 hover:tw-text-green-500" type="submit" value="Yes - Send Alarm" />}
          </form>
        )

      case ActionState.annotation:
        return (
          <div className={"tw-flex tw-flex-col tw-border-2 tw-border-gray-200 tw-rounded-xl tw-items-center"}>
            <AnnotationForm title="Create Annotation" annotationFormData={annotationFormData}
                            disableOptions={["NO"]}
                            timeRequired={true}
                            setAnnotationFormData={setAnnotationFormData}
                            onSubmit={
                              () => {
                                (annotationFormData.start_time !== undefined && annotationFormData.end_time !== undefined) &&
                                createAndPostAnnotation(annotationFormData.start_time, annotationFormData.end_time, annotationFormData.tags.concat([ANOMALY_REVIEW_TAG + "_anomaly"]),
                                  annotationFormData.description, annotationFormData.anomaly!, ActionState.default)
                              }}
                            onCancel={() => setActionState(ActionState.default)}
            />
          </div>
        )

      case ActionState.markingDone:
        return (
          <div className={"tw-flex tw-flex-col tw-border-2 tw-border-gray-200 tw-rounded-xl tw-items-center"}>
            <div className={"tw-p-2"}>This will mark the last 7 days as completely annotated</div>
            <div className={"tw-flex tw-space-x-1 tw-mb-2"}>
              <Button type="button" size="large" variant="secondary"
                      styles={"tw-max-w-xs"} onClick={() => {
                setActionState(ActionState.default)
              }}>
                <span>Cancel</span>
              </Button>
              <Button type="button" size="large" variant="secondary"
                      styles={"tw-flex tw-max-w-xs tw-bg-green-200"} onClick={() => {
                let now = new Date()
                createAndPostAnnotation(new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000), now,
                  [ANOMALY_REVIEW_TAG + "_checked_period"],
                  "Period checked", "NO", ActionState.default)
              }}>
                <span>Confirm Done</span>
              </Button>
            </div>
          </div>
        )
      case ActionState.done:
        return (<div></div>)
    }
  }

  function filter_and_sort_period_checked_annotations(annotations: Annotation[]){
    return annotations.filter(an => an.tags?.includes(ANOMALY_REVIEW_TAG + "_checked_period"))
        .sort((a, b) => a.end_time-b.end_time)
  }
  function renderLastDate(){
    let period_checked_annotations = filter_and_sort_period_checked_annotations(annotations)

    if(period_checked_annotations.length > 0){
      return new Date(period_checked_annotations[0].end_time*1000).toLocaleString()
    }
    return "Not checked in the last 90 days"
  }

  return (
    <SectionWrapper styles={"tw-m-10 tw-flex tw-flex-col tw-items-center tw-p-4"} key={device.id}>
      <div className={"tw-text-2xl tw-flex tw-flex-row tw-space-x-8 tw-items-center"}>
        <TrafficLight status={status.deviceStatus.status} />
        <p>Device: {device.serial ?? "Unknown"}</p>
        <p>Organization: {status.organization ? status.organization.name : "Unknown"}</p>
        <p>Building: {status.building ? status.building.name ?? status.building.address : "Unknown"}</p>
        <p>Asset: {status.deviceDeployment?.assets ? status.deviceDeployment?.assets[0].name : "Unknown"}</p>
        <p>Room: {status.room ? status.room.name ?? status.room.nice_name : "Unknown"}</p>
      </div>
      <AnomalyGraph device={device}
                    modelTemplates={modelTemplates}
                    anomalyGraphDataMap={anomalyGraphDataMap}
                    alarms={existingAlarms}
                    onGraphClick={handleGraphClick}
                    className="tw-w-full"
                    addCustomPlotData={addCustomDataFromForm}
                    addCustomPlotLayout={addCustomLayout}
                    onUpdateGraphTime={st => setSelectedTime(st)}
                    selectedGraphTime={selectedTime}
                    showProbability={true}
                    probabilityVisible={anomalyProbabillityVisible}
                    hovermode={actionState == ActionState.alarm || actionState == ActionState.annotation ? "x unified" : "closest"}
                    loading={loading}
      />
      <div className={"tw-mb-2"}>Last Checked: {renderLastDate()}</div>
      {
        renderActionState(actionState)
      }
    </SectionWrapper>
  )
}